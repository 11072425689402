import { NavLink } from "react-router-dom"

const Nav = () => {

    

    return (
        <div className="nav">
            <NavLink className="nav-link icon-portfolio" to="/portfolio">
                <img src="/icons/portfolio.svg" alt="Portfolio" />
                <div>Portfolio</div>
            </NavLink>
            <a className="nav-link icon-facebook" rel="noreferrer" href="https://www.facebook.com/andymaniwxe/" target="_blank">
                <img src="/icons/facebook_square.svg" alt="Facebook" />
                <div>Facebook</div>
            </a>
            <a className="nav-link icon-instagram" rel="noreferrer" href="https://www.instagram.com/andreas_k_olsen/" target="_blank">
                <img src="/icons/instagram.svg" alt="Instagram" />
                <div>Instagram</div>
            </a>
        </div>
    )
}

export default Nav