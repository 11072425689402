

const Forside = () => {
    
    function yearsActive() {
        var date = new Date().getFullYear()
        var startYear = 2018
        return (date - startYear)
    }
    
    return (
        <div className="frontpage-wrapper">
            <div className="frontpage-left" />
            <div className="frontpage-right">
                <div className="frontpage-centered">
                    <div className="frontpage-section">
                        <b>ANDREAS K. OLSEN</b>
                    </div>
                    <div className="frontpage-section">
                        Frilansfotograf og utvikler, født og oppvokst på vestlandet. Jeg har nå holdt på med landskaps- og portrettfotografering i {yearsActive()} år, og ønsker gjerne å utvikle meg videre.
                    </div>
                    <div className="frontpage-section">
                        &nbsp;
                    </div>
                    <div className="frontpage-section">
                        Ta gjerne kontakt om du ønsker billige (kanskje gratis?) fotograf-tjenester. Alt du finner av fotografier på nettsiden min er også til salgs, pris varierer etter print-størrelse.
                    </div>
                 </div>
            </div>
        </div>
    )
}

export default Forside