import { Routes, Route } from "react-router-dom";

import Header from './modules/Header'

import Forside from './pages/Forside'
import Portfolio from './pages/Portfolio'

const App = () => {
  return (
      <>
          <Header />
          <Routes>
              <Route path="/" element={<Forside />} />
              <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
      </>
  )
}

export default App