import { Fancybox } from "@fancyapps/ui" // eslint-disable-line no-unused-vars

const Portfolio = () => {
    
    function shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]
        }
        return array
    }
    
    const imgTemp = (require.context('/public/portfolio/thumbnail', false, /\.(png|jpe?g|svg)$/)).keys()
    let images = []
    imgTemp.forEach(function callback(value, index) {
        images.push(value.slice(1))
    })
    console.log(images)
    images = shuffle(images)
    console.log(images)
    
    return (
        <div className="portfolio-wrapper">
            <div className="section">
                <div className="gallery">
                    {images && images.map((image, key) => {
                        return (
                            <img
                                key={key}
                                className="gallery-image"
                                src={"/portfolio/thumbnail" + image}
                                data-src={"/portfolio/full" + image}
                                data-fancybox="gallery"
                                alt="Gallery item"
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Portfolio