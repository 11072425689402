import { NavLink } from "react-router-dom"

import Nav from './Nav'

const Header = () => {

    

    return (
        <div className="header">
            <div className="centered header-container">
                <NavLink className="header-logo" to="/">
                    <img src="/icons/logo.svg" alt="Logo" />
                </NavLink>
                <Nav />
            </div>
        </div>
    )
}

export default Header